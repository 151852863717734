import React from 'react';
import {graphql} from 'gatsby'

import Layout from '../components/Layout';
import PopularProducts from '../components/PopularProducts';
import ContactForm from '../components/ContactForm';

const ContactPageTemplate = ({data})=> {
    const content = data.markdownRemark.frontmatter;
    return (
        <Layout>
            <section className="main-section product-details-page">
                <div className="container tab-container no-margin-top no-border">
                    <div className="tab-content flexbox-space-between flex-wrap align-top">
                        <div className="section-copy">
                            <div className="section-heading">{content.header}</div>
                            {
                                content.paragraphs.map((paragraph, index)=>(
                                    <div key={index}>
                                        <h3>{paragraph.paragraph.subheader}</h3>
                                        <p className="subtitle-paragraph">{paragraph.paragraph.text}</p>
                                    </div>
                                ))
                            }
                        </div>

                        <ContactForm content={content.form} />
                    </div>
                </div>
            </section>

            <PopularProducts />
        </Layout>
    );
};

export default ContactPageTemplate;

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        paragraphs{
          paragraph{
            subheader
            text
          }
        }
        form{
            header 
            email_label
            email_placeholder
            first_name_label 
            first_name_placeholder
            last_name_label 
            last_name_placeholder
            button_label
            textarea_label
            textarea_placeholder
            user_email_error_message 
            first_name_error_message 
            last_name_error_message 
            text_area_error_message 
        }
      }
    }
  }
`