import { navigate } from "gatsby";
import sendEmail from './api_requests';

import React from 'react';
import {Form, Icon, Input, Button} from 'antd';
const FormItem = Form.Item;

class NormalForm extends React.Component {
    constructor(props) {
        super(props);
        this.content = this.props.content;
        this.state={request_status:''};
    }

    //TODO: for textarea add custom message when user adds a message to long /mk
    //TODO: display success or error messages on form submit or redirect to success or error pages /mk

    handleSuccessEvent=(response)=>{
         if(response.status === 'ok'){
            navigate('/success');
        }else{
            console.log('error: ', response);
            this.setState({request_status: ''});
            navigate('/error', { state: { redirect_from_page: "contact"}});
        }
    };

    handleErrorEvent=(response)=>{
        console.log('error: ', response);
        navigate('/error', { state: { redirect_from_page: "contact"}});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.request_status === "sending"){
            return;
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({request_status: 'sending'});
                values.email_type = "contact";
                sendEmail(values).then(this.handleSuccessEvent.bind(this)).catch(this.handleErrorEvent.bind(this));
            }
        });
    };

    render(){
        const {getFieldDecorator} = this.props.form;

        return (
            <div className="section-feature-list">
                <div className="checkout-card">

                    <Form onSubmit={this.handleSubmit} className="simple-form">
                         <h2>{this.content.header}</h2>

                        <FormItem>
                            {getFieldDecorator('user_email', {
                                rules: [{required: true, whitespace:true, message: this.content.user_email_error_message}],
                            })(
                                <label>
                                    <span className="label-text">{this.content.email_label}</span>
                                    <Input type="email" name="email" placeholder={this.content.email_placeholder} />
                                </label>
                            )}
                        </FormItem>

                        <div className="flexbox-space-between no-alignment">
                             <FormItem>
                                {getFieldDecorator('first_name', {
                                    rules: [{required: true, whitespace:true, min:3, message: this.content.first_name_error_message}],
                                     
                                })(
                                    <label>
                                        <span className="label-text">{this.content.first_name_label}</span>
                                        <Input type="text" name="first-name" placeholder={this.content.first_name_placeholder}/>
                                    </label>
                                )}
                            </FormItem>

                             <FormItem>
                                {getFieldDecorator('last_name', {
                                    rules: [{required: true, whitespace:true, min:3, message: this.content.last_name_error_message}],

                                })(
                                    <label>
                                        <span className="label-text">{this.content.last_name_label}</span>
                                        <Input type="text" name="last-name" placeholder={this.content.last_name_placeholder}/>
                                    </label>
                                )}
                            </FormItem>
                        </div>

                        <FormItem>
                                {getFieldDecorator('message', {
                                    rules: [{required: true, whitespace:true, max:50, message: this.content.text_area_error_message}],

                                })(
                                     <label>
                                         <span className="label-text">{this.content.textarea_label}</span>
                                         <textarea name="message" placeholder={this.content.textarea_placeholder} ></textarea>
                                     </label>
                                )}
                            </FormItem>


                        <FormItem>
                            <Button type="primary" htmlType="submit" className="cta big full-width center-align">
                                {this.content.button_label}
                                {this.state.request_status === "sending" && <Icon type="loading" style={{ fontSize: 30, position: 'absolute' }} spin />}
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}

const ContactForm = Form.create()(NormalForm);

export default ContactForm;